import React from 'react';
import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

import classes from './Share.module.css';

const Share = props => {
    const { comic } = props;
    
    return (
        <div className={classes.share}>
        <OverlayTrigger
          key="copy-link"
          placement="left"
          overlay={
            <Tooltip id="copy-link" >
              Click to Copy Link
            </Tooltip>
          }
        >
            <Button variant="link"
                onClick={() => {
                    navigator.clipboard.writeText(`https://crimeshow.art/comics#${comic.link}`);
                }} >
                crimeshow.art/comics#{comic.link}
            </Button>              
            {/* https://www.folkstalk.com/2022/07/click-to-copy-react-with-code-examples.html */ }
        </OverlayTrigger>
        </div>
    );
};

export default Share;