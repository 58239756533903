import React from 'react';

import classes from './Comics.module.css';

import Comic from './Comic';

const Comics = props => {
    const { comicData } = props;
    
    return (
        <div className={classes.container}>
            {comicData.map(comic =>
                <Comic comic={comic} />
            )}
        </div>
    );
};

export default Comics;