import React, { useEffect } from 'react';

import classes from './Comic.module.css';

import Share from './Share';
import Transcript from './Transcript';

const Comic = props => {
    const { comic } = props;


    // https://stackoverflow.com/questions/72490668/how-to-navigate-to-a-specific-section-of-the-page-on-page-load-using-react-route
    useEffect(() => {
        const urlHash = window.location.hash;
        if (urlHash.length) {
          const element = document.getElementById(urlHash.substring(1));
          if (element) {
            element.scrollIntoView();
          }
        }
     }, );
    
    return (
        <div className={classes.comic} key={comic.id}  id={comic.link}>
            <h2>{comic.title}</h2>
            <p>
                by Emma Vogt
                | {comic.date}
            </p>

            <img src={`images/comics/${comic.image}`} 
                onError={(e)=>{e.target.onerror = null; 
                e.target.src="images/comics/not-released.png"}} 
                alt={comic.title} 
            /> 
            {/* Uses filler image when there's no matching image in the folder */}

            <Share comic={comic} />

            <p className={classes.copyright}>
                &copy; Emma Vogt
            </p>

            <Transcript comic={comic} />
        </div>
    );
};

export default Comic;