import React from 'react';
import { Link } from "react-router-dom";

import classes from './About.module.css'

const About = props => {
    return (
        <div className={classes.about}>
            <h2>About Crime Show</h2>
            <p>
                In <em>Crime Show</em>, Detectives Lisa Copper and Franklin Fuzz set out to fight crime, 
                but end up solving the mysteries of crime show tropes instead. 
                This loving-parody comic series questions the usual suspects, 
                everything from witticisms to high heels, and brings them to justice! 
                Or, well, makes a comic about them, at least.
            </p>
            <p>
                You can find Crime Show <Link to='/comics'>comics on this website</Link>, 
                on <a href="https://tapas.io/series/crimeshow/info" target="_blank" rel="noreferrer">Tapas</a>, 
                as well as <a href="https://www.instagram.com/emma.vogt.art/" target="_blank" rel="noreferrer">Instagram</a>.
            </p>
            <p>
                <img src="images/about/lisa_and_franklin.png" alt="Franklin Fuzz stands proudly with a police badge; Lisa Copper rolls her eyes at him, also carrying a police badge" />
            </p>
            <h3>About the Characters</h3>
            <p className={classes.aboutBio}>
                <img src="images/about/lisa-copper.png" alt="Detective Lisa Copper has long wavy red hair and a sharp navy blue suit" />
                Lisa Copper is a smart and sassy detective who takes her work very seriously. 
                Her humor tends to materialize as snark and eye-rolls. 
                Lisa's observational skills allow her to poke holes in logic and solve cases, 
                as well as keep a handle on her partner.
            </p>
            <p className={classes.aboutBio}>
                <img src="images/about/franklin-fuzz.png" alt="Detective Franklin Fuzz has styled dark brown hair and a classy dark gray suit" />
                Franklin Fuzz is a detective with a lot of heart, as well as a sense of humor. 
                This humor tends to come out in goofy dad jokes and puns. 
                All that said, at the end of the hour he'll still solve the case, 
                two-parters and season finale cliffhangers notwithstanding. 
            </p>
            
            <h3>About the Creator</h3>
            <p className={classes.aboutBio} >
            <img src="images/about/emma-vogt.png" alt="Creator Emma Vogt is a redhead wearing a green jacket" />
                <em>Crime Show</em> is a webcomic created by Emma Vogt. 
                Watching crime dramas was a big part of her life growing up, 
                and she continues to watch them to this day. 
                You can also find Emma on <a href="https://emmavogt.com/" target="_blank" rel="noreferrer">emmavogt.com</a>
            </p>
            
            
        </div>
    );
};

export default About;