import React from 'react';
//import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';

import classes from "./Transcript.module.css";

const Transcript = props => {
    const { comic } = props;
    // const [displayTranscript, setDisplayTranscript] = useState(false);

    // const displayTranscriptHandler = () => {
    //     setDisplayTranscript(!displayTranscript);
    // };

    return (
        <div className={classes.transcript}>
            {/* <Card>
                <Card.Header>
                    <div className="d-grid gap-1">
                        <Button variant="link" onClick={displayTranscriptHandler} block>
                            Transcript
                        </Button>
                    </div>
                </Card.Header>
                        {displayTranscript && <p>{comic.transcript}</p>}

            </Card> */}
            <Card>
                <Card.Header>
                    <div className="d-grid gap-1">
                        Transcript
                    </div>
                </Card.Header>
                <Card.Body>
                    <Card.Text>
                        <p>{comic.transcript.join("\n")}</p>
                    </Card.Text>
                </Card.Body>
            </Card>
        </div>
    );
};

export default Transcript;